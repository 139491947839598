import autocomplete, { AutocompleteItem } from 'autocompleter';

type AutocompleteApiItem = {
  title: string;
};

document
  .querySelectorAll<HTMLInputElement>('input[data-autocomplete]')
  .forEach(($input) => {
    autocomplete<AutocompleteApiItem & AutocompleteItem>({
      className: 'large-input__autocomplete',
      disableAutoSelect: true,
      input: $input,
      minLength: 3,
      emptyMsg: 'Keine Ergebnisse gefunden',
      preventSubmit: false,
      render(item) {
        const $item = document.createElement('div');
        $item.textContent = item.title;

        return $item;
      },
      async fetch(text, update) {
        // nosemgrep: nodejs_scan.javascript-ssrf-rule-node_ssrf
        const results = await fetch(`${$input.dataset.autocomplete}${text}`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            Accept: 'application/json',
          },
        });

        const items: AutocompleteApiItem[] = await results.json();
        update(items.slice(0, 5));
      },
      onSelect(item) {
        $input.value = item.title;
      },
      customize(_, __, $container) {
        $container.classList.remove('autocomplete');

        $container.querySelectorAll('[role="option"]').forEach(($option) => {
          $option.classList.remove('selected');
          $option.classList.add('large-input__autocomplete-option');
        });

        $container.querySelectorAll('.empty').forEach(($empty) => {
          $empty.classList.remove('empty');
          $empty.classList.add('large-input__autocomplete-option');
          $empty.classList.add('large-input__autocomplete-option--empty');
        });
      },
    });
  });
