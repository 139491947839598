import 'share-api-polyfill';
import { on } from 'delegated-events';

on('click', 'button.js-share', async (event) => {
  event.preventDefault();

  const { currentTarget: $target } = event;
  const {
    url = window.location.href,
    title = document.title,
    text,
  } = $target.dataset;

  try {
    // Start sharing
    await window.navigator.share(
      {
        title,
        text,
        url,
      },
      {
        copy: true,
        email: true,
        print: true,
        sms: false,
        messenger: true,
        facebook: true,
        whatsapp: true,
        twitter: true,
        linkedin: true,
        telegram: false,
        skype: false,
        language: 'de',
      },
    );

    // Push event
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'share',
    });
  } catch (e) {
    // Ignore error
  }
});
