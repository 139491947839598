import { on } from 'delegated-events';

on('click', 'input.input__input[data-select-on-click]', (event) => {
  const { currentTarget: $input } = event;

  $input.select();
  $input.setSelectionRange(0, $input.value.length);
});

on('keypress', 'input.input__input--digit-code', (event) => {
  const { key } = event;

  if (!key.match(/^[0-9]$/)) {
    event.preventDefault();
  }
});

on('paste', 'input.input__input--digit-code', (event) => {
  const { currentTarget: $input, clipboardData } = event;

  event.preventDefault();

  const pasteValue = clipboardData?.getData('text');
  if (pasteValue) {
    $input.value = pasteValue.trim().replace(/\D/g, '');
  }
});

document
  .querySelectorAll<
    HTMLInputElement | HTMLTextAreaElement
  >('input.input__input[data-limit], textarea.input__input[data-limit]')
  .forEach(async ($input) => {
    const { default: useInputLimits } = await import(
      /* webpackChunkName: "use-input-limits" */ './useInputLimits'
    );
    useInputLimits($input);
  });

document
  .querySelectorAll<HTMLInputElement>('input.input__input[data-countries]')
  .forEach(async ($input) => {
    const { default: useIntlTelInput } = await import(
      /* webpackChunkName: "use-intl-tel-input" */ './useIntlTelInput'
    );
    useIntlTelInput($input);
  });

document
  .querySelectorAll<HTMLInputElement>('input.input__input[data-richtext]')
  .forEach(async ($input) => {
    const { default: usePell } = await import(
      /* webpackChunkName: "use-pell" */ './usePell'
    );
    usePell($input);
  });

document
  .querySelectorAll<HTMLInputElement>('input.input__input[data-tags]')
  .forEach(async ($input) => {
    const { default: useTags } = await import(
      /* webpackChunkName: "use-tags" */ './useTags'
    );
    useTags($input);
  });

document
  .querySelectorAll<HTMLElement>('.input__digit-code-input')
  .forEach(($el) => {
    $el.scrollLeft = 0;
    $el.addEventListener(
      'scroll',
      () => {
        $el.scrollLeft = 0;
      },
      { passive: true },
    );
  });
